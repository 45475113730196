import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import zhCN from 'altihex-design/es/locale/zh_CN'
import LayoutMain from '../core/component/layoutMain'
import { useRoute } from 'vue-router'
import { isAssets } from './utils'

function duOtelCustomError(errorStack: { frames: string }): { frames: string } {
  try {
    const microApp = document.querySelector('micro-app')
    if (!microApp) return errorStack
    const { frames } = errorStack
    // 根据apm尝试得出microapp 在当前版本号下偏移量
    const colnoOffest = 388
    const linenoOffet = 2
    const microSourceMapList = frames && JSON.parse(frames)
    const microSourceMap = microSourceMapList[microSourceMapList.length - 1]
    microSourceMap.colno = microSourceMap.colno - colnoOffest
    microSourceMap.lineno = microSourceMap.lineno - linenoOffet
    errorStack.frames = JSON.stringify(microSourceMapList)
    return errorStack
  } catch (err) {
    console.log('onCustomError报错:', err)
    return errorStack
  }
}
window?.DUOTEL?.setConfig({
  release: process.env.APM_SOURCEMAP_VERSION, // webpack或者vite等定义的环境变量；
  prefix: isAssets() ? '/lms-admin/' : '/',
  onCustomError: duOtelCustomError,
})
export default defineComponent({
  name: 'App',

  components: {
    LayoutMain,
  },
  setup() {
    const usestore = useStore()
    const route = useRoute()
    const spinning = computed(() => usestore.state._Altihex_.spinning)
    console.log('app.tsx route', route, route.path)
    return () => (
      <a-spin spinning={spinning.value}>
        <a-config-provider locale={zhCN}>
          <router-view key={route.name} />
        </a-config-provider>
        {/*<layout-main />*/}
      </a-spin>
    )
  },
})
